import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { FC } from "react";

type AlertType = "error" | "warning" | "info";

interface Props {
  type: AlertType;
  title?: string;
  message: string;
  onClose: () => void;
}

const colors = {
  error: "error.main",
  warning: "warning.main",
  info: "info.main",
};

const AlertModal: FC<Props> = ({
  type = "info",
  title = "",
  message,
  onClose,
}) => {
  const _onClose = () => {
    onClose && onClose();
  };

  return (
    <Stack
      rowGap={2}
      sx={{
        maxWidth: "570px",
        width: {
          xs: "100%",
          sm: "570px",
        },
        p: "33px",
      }}
    >
      <Typography
        fontSize={"24px"}
        fontWeight={600}
        mb="16px"
        sx={{
          color: colors[type] as any,
        }}
      >
        {title}
      </Typography>
      <Typography fontSize={"14px"} color="#304263" mb="40px">
        {message}
      </Typography>
      <Button
        onClick={_onClose}
        sx={{
          width: "220px",
        }}
        variant="primary"
      >
        Ok
      </Button>
    </Stack>
  );
};

export default AlertModal;
