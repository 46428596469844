import { parseCookies } from "nookies";
import { setNotificationState } from "../../../../ui/Notification";

export function downloadBlob(url: any, name = "qr.png") {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  //const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  //link.href = url;
  link.setAttribute("href", url);
  link.setAttribute("download", name);

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}

export const generateSVG = async (
  qr: any,
  value: string,
  res: number = 500,
  type: "svg" | "png",
  cb: any,
  exportName: string,
  name?: string
) => {
  const { joytoken } = parseCookies();

  const data = await fetch(process.env.NEXT_PUBLIC_URL + "qr/" + type, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      authorization: joytoken ? `Bearer ${joytoken}` : "",
    },
    body: JSON.stringify({
      state: qr,
      exportWidth: res,
      value,
      id: name,
      exportName,
    }),
  });

  const d = await data.blob();
  return cb(URL.createObjectURL(d), exportName + "." + type);
};
