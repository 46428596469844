import {
  ClickAwayListener,
  FilledTextFieldProps,
  Popover,
  TextField,
  useThemeProps,
} from "@mui/material";
import { Box, Stack, useTheme } from "@mui/system";
import { colord } from "colord";
import React, { FC, useEffect, useMemo, useState } from "react";
import { RgbaColorPicker } from "react-colorful";
import { ColorType } from "../../generated/graphql";
import Slider from "./Slider";

export type InputColorSlider = {
  min: number;
  step: number;
  max: number;
  value: number;
  onChange: (e: any) => void;
};

export type InputGradientSwitch = {
  onSelectGradient: (t: ColorType) => void;
};

interface Props
  extends Omit<FilledTextFieldProps, "variant" | "onBlur" | "onChange"> {
  opened?: boolean;
  onChange: (v: string, id?: string) => void;
  onBlur?: (v: string, id: string) => void;
  sliderProps?: InputColorSlider;
  gradientSwitch?: InputGradientSwitch;
}

const InputColor: FC<Props> = ({
  value,
  opened = false,
  onChange,
  onBlur,
  sx,
  id,
  sliderProps,
  gradientSwitch,
  ...props
}) => {
  const [_opened, setOpened] = useState(opened);

  let _color = useMemo(() => {
    return colord(value as any).toRgb();
  }, [value]);

  const onChangeInput = (e: any) => {
    const hexcolor = /^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$/i;
    if (hexcolor.test(e.target.value)) {
      onChange && onChange(e.target.value, id);
    }
  };

  const onChangePicker = (e: any) => {
    const hex = colord(e).toHex();
    onChange && onChange(hex, id);
  };

  const _onBlur = (e: any) => {
    onBlur && onBlur(e.target.value, id || "");
  };

  const _onBlurPicker = (e: any) => {
    onBlur && onBlur(value as string, id || "");
  };

  const _onClose = () => {
    onBlur && onBlur(value as string, id || "");
    setOpened(false);
  };

  const changeOpenState = () => {
    setOpened(value !== "" ? !_opened : false);
  };

  useEffect(() => {
    setOpened(opened);
  }, [opened]);

  return (
    <Stack
      tabIndex={0}
      sx={{
        width: "100%",
        flexDirection: "row",
        backgroundColor: "background.default",
        borderRadius: "15px",
        justifyContent: "space-between",
        columnGap: 1,
        position: "relative",
        "&:focus-within ": {
          outline: !!value ? "1px solid #0F75FB" : "none",
        },
        ...(sx as any),
      }}
    >
      <Box
        onClick={changeOpenState}
        sx={{
          width: "37px !important",
          height: "37px !important",
          borderRadius: "12px",
          backgroundColor: value as string,
          ml: 1,
          my: 1,
          cursor: value !== "" ? "pointer" : "not-allowed",
        }}
      />
      <TextField
        value={value}
        {...props}
        disabled={!value}
        onClick={changeOpenState}
        onChange={onChangeInput}
        onBlur={_onBlur}
        sx={{
          width: "calc(100% - 53px)",
          "& .MuiInputBase-input": {
            pl: "12px",
            pt: "24px",
          },
          "& .MuiInputBase-root": {
            "&:hover": {
              backgroundColor: "transparent",
            },
            backgroundColor: "transparent",
            borderRadius: "15px",
            "&.Mui-focused": {
              outline: "0px solid #0F75FB",
            },
          },
        }}
      />
      {_opened && (
        <ClickAwayListener onClickAway={_onClose}>
          <Box
            sx={{
              position: "absolute",
              zIndex: 8,
              top: "101%",
              p: 3,
              width: "100%",
              maxWidth: "320px",
              flexDirection: "column",
              backgroundColor: "#fff",
              display: "flex",
              rowGap: 1,
              borderRadius: "13px",
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)",
              "& .react-colorful": {
                width: "100%",
              },
              "& .react-colorful__pointer": {
                width: "15px",
                height: "15px",
                cursor: "pointer",
              },
              "& .react-colorful__hue": {
                my: 1,
                borderRadius: "500px",
                height: "15px",
              },
              "& .react-colorful__alpha": {
                borderRadius: "500px",
                height: "15px",
              },
              "& .react-colorful__saturation-pointer": {
                width: "25px",
                height: "25px",
              },
            }}
          >
            <RgbaColorPicker
              color={_color}
              onChange={(e: any) => onChangePicker(e)}
              //     onBlur={(e: any) => _onBlurPicker(e)}
            />
            <Stack columnGap={1} flexDirection="row" alignItems="center">
              {gradientSwitch && (
                <>
                  <Box
                    onClick={() =>
                      gradientSwitch.onSelectGradient(ColorType.LinearGradient)
                    }
                    sx={{
                      width: "20px !important",
                      height: "20px",
                      border: "2px solid #CDCECF",
                      borderRadius: "3px",
                      cursor: "pointer",
                      background:
                        "linear-gradient(180deg, #04080F 32.5%, rgba(217, 217, 217, 0) 100%)",
                    }}
                  />
                  <Box
                    onClick={() =>
                      gradientSwitch.onSelectGradient(ColorType.RadialGradient)
                    }
                    sx={{
                      width: "20px !important",
                      height: "20px",
                      border: "2px solid #CDCECF",
                      borderRadius: "3px",
                      cursor: "pointer",
                      background:
                        "radial-gradient(60% 60% at 50% 50%, #04080F 0%, rgba(217, 217, 217, 0) 100%)",
                    }}
                  />
                </>
              )}
              {sliderProps && <Slider {...sliderProps} sx={{ width: "79%" }} />}
            </Stack>
          </Box>
        </ClickAwayListener>
      )}
    </Stack>
  );
};

export default InputColor;
