import { Stack, StackTypeMap } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import { Icon } from "../../ui";

interface Props {
  panelStyle?: StackTypeMap["props"]["sx"];
}

const RightPanelContainer: FC<PropsWithChildren<Props>> = ({
  children,
  panelStyle,
}) => {
  return (
    <Stack
      flex={1}
      id="panel-container"
      sx={{
        maxWidth: {
          xs: "100%",
          sm: 375,
        },
        height: "fit-content",
        position: {
          xs: "initial",
          md: "sticky",
        },
        mb: {
          xs: 3,
          md: 0,
        },
        top: "178px",
        backgroundColor: "#fff",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)",
        borderRadius: "25px",
        alignItems: "center",
        p: 3,
        ...(panelStyle && (panelStyle as any)),
      }}
    >
      <Icon
        type="DecorationLines"
        sx={{
          position: "absolute",
          left: -34,
          top: -34,
          display: {
            xs: "none",
            md: "inline-block",
          },
        }}
      />
      {children}
    </Stack>
  );
};

export default RightPanelContainer;
