import { GradientType, QrStateI } from "@joyqr/qrcreator/dist/qrstate";

export const defaultFrameState = {
  padding: 0,
  borderColor: "#000000",
  borderWidth: 10,
  frameHeightIncerement: 0,
  content: [],
  type: "rect",
};

export const FrameInitialState = {
  bgColor: {
    firstColor: "#ffffff",
    secondColor: "#000000",
    type: GradientType.Flat,
    angle: 90,
  },
  stroke: {
    firstColor: "#000000",
    secondColor: "#ffffff",
    type: GradientType.Flat,
    angle: 90,
  },
};

export const initialState: QrStateI = {
  // padding: 0.17,
  frame: {
    id: -1,
    custom: null,
  },
  eyes: {
    lT: {
      externalId: 2,
      internalId: 2,
      external: "#000000",
      internal: "#000000",
    },
    rT: {
      externalId: 2,
      internalId: 2,
      external: "#000000",
      internal: "#000000",
    },
    lB: {
      externalId: 2,
      internalId: 2,
      external: "#000000",
      internal: "#000000",
    },
  },
  module: {
    id: 2,
    color: {
      firstColor: "#000000",
      secondColor: "#8BC2E3",
      type: GradientType.Flat,
      angle: 0,
    },
  },
  shape: {
    type: "Circle",
    bgColor: {
      type: GradientType.Flat,
      firstColor: "#ffffff",
      secondColor: "#000000",
      angle: 0,
    },
    bgSize: 90,
    strokeColor: {
      type: GradientType.Flat,
      firstColor: "#000000",
      secondColor: "#ffffff",
      angle: 0,
    },
    stroke: 10,
    //   strokeBorder: 0,
    //   strokeBorderColor: "#fff",
    //  bgBorder: 0,
    //  bgBorderColor: "#fff",
  },
  logo: null,
  copyText: true,
};
