import { Stack } from "@mui/system";
import React, { FC } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button, Avatar, Typography } from "@mui/material";

interface Props {
  image: string | any | null;
  title: string;
  formats: string[];
  maxSize: string;
  onRemove: () => void;
  onClick?: () => void;
}

const UploadImage: FC<Props> = ({
  image,
  title,
  formats,
  maxSize,
  onRemove,
  onClick,
}) => {
  return (
    <Stack
      onClick={image ? undefined : onClick}
      sx={{
        backgroundColor: "background.default",
        justifyContent: "center",
        alignItems: "center",
        p: "10px",
        borderRadius: "15px",
        cursor: "pointer",
      }}
    >
      {image ? (
        <>
          {typeof image === "string" ? (
            <Avatar
              onClick={onClick}
              src={image}
              sx={{
                mt: 1,
                mb: 1,
                width: 64,
                height: 64,
              }}
            />
          ) : (
            image
          )}
          <Button onClick={onRemove} color="error">
            Remove
          </Button>
        </>
      ) : (
        <>
          <CloudUploadIcon
            sx={{
              fontSize: 50,
            }}
          />
          <Typography fontSize={14} lineHeight={"21px"}>
            {title}
          </Typography>
          <Typography fontSize={14}>
            <Typography
              component="span"
              fontSize={14}
              fontWeight={700}
              color="#022B51"
            >
              Allowed Format:{" "}
            </Typography>
            {formats.join(" ")}
          </Typography>
          <Typography fontSize={14}>
            <Typography
              component="span"
              fontSize={14}
              fontWeight={700}
              color="#022B51"
            >
              {" "}
              Max Filze Size:{" "}
            </Typography>
            {maxSize}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default UploadImage;
