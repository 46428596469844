import React, { FC, PropsWithChildren, useEffect } from "react";
import { Stack } from "@mui/system";
import {
  Box,
  BoxProps,
  Fab,
  StackProps,
  StackTypeMap,
  useMediaQuery,
} from "@mui/material";
import { Icon } from "../../ui";
import { useModal } from "../../ui/Modal";
import RightPanelContainer from "./RightPanelContainer";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import Modal from "../reusable/modals/Modal";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { useRouter } from "next/router";
import useActiveDashboard from "../../utils/useActiveDashboard";

interface Props {
  panel?: any;
  panelStyle?: StackTypeMap["props"]["sx"];
  childrenStyle?: StackTypeMap["props"]["sx"];
}

export const MobileButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const dashboard = useActiveDashboard();

  return (
    <Fab
      onClick={onClick}
      //@ts-ignore()
      color={dashboard === "link" ? "info" : "purple"}
      aria-label="open preview"
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
      }}
    >
      {dashboard === "link" ? (
        <QrCodeIcon sx={{ fill: "#fff" }} />
      ) : (
        <RememberMeIcon sx={{ fill: "#fff" }} />
      )}
    </Fab>
  );
};

const PageLayout: FC<PropsWithChildren<Props>> = ({
  children,
  panelStyle,
  panel,
  childrenStyle,
}) => {
  const dashboard = useActiveDashboard();
  const t = dashboard === "link" ? "Your QR Code" : "Your Bio Page";

  const match = useMediaQuery("(max-width: 600px)");
  const { renderModal, openModal, closeModal, isOpened } = useModal();

  const _onClickMobileButton = () => {
    openModal(
      <Modal title={t} close={closeModal}>
        {panel}
      </Modal>
    );
  };

  return (
    <>
      {renderModal()}
      {panel ? (
        <Stack
          mb="150px"
          width={"100%"}
          maxWidth="1200px"
          position="relative"
          margin="auto"
          flexDirection={[
            "column-reverse",
            "column-reverse",
            "column-reverse",
            "row",
          ]}
          justifyContent="space-between"
          pt={8}
          sx={{
            columnGap: 2,
            alignItems: {
              xs: "center",
              md: "start",
            },
          }}
        >
          <Stack
            flex={2}
            maxWidth="695px"
            mb="150px"
            id="links-container"
            width="100%"
            sx={{
              px: {
                xs: "13px",
                mg: 2,
              },
              ...(childrenStyle && (childrenStyle as any)),
            }}
          >
            {children}
          </Stack>
          {!isOpened && (
            <RightPanelContainer
              panelStyle={{
                ...panelStyle,
                display: {
                  xs: "none",
                  sm: "initial",
                },
              }}
            >
              {panel}
            </RightPanelContainer>
          )}
          {match && <MobileButton onClick={_onClickMobileButton} />}
        </Stack>
      ) : (
        <Stack
          margin="auto"
          maxWidth="1200px"
          pb="300px"
          sx={{
            px: {
              xs: "13px",
              md: 2,
              lg: 0,
            },
            ...(childrenStyle && (childrenStyle as any)),
          }}
        >
          {children}
        </Stack>
      )}
    </>
  );
};

export default PageLayout;
